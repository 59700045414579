/* API */
import api from '@/api/ajax/settings';

/* 型別 */
import type { AxiosPromise } from 'axios';
import type ResponseBaseModel from '@sms/common/interfaces/ResponseBaseModel';
import type LocationSet from '@sms/common/models/LocationSet';
import type { ISearchModel } from '@sms/common/composables/useSearch';
import type SearchResponseBaseModel from '@sms/common/interfaces/SearchResponseBaseModel';

export class LocationQueryModel {
  ParentId = '';

  SearchContents = '';

  Code = '';

  Name = '';
}

export interface GetLocationCodePayload {
  ParentId: string;
  LocationTypeCode: string;
  GameTypeCode?: string;
  CustomerCode?: string;
  CatalogCode?: string;
}

export interface GetLocationTreePayload {
  NeedFlatten?: boolean;
  TypeFilter?: string[];
  OrderBy?: string;
}

export interface GetMemberLocationTreePayload extends GetLocationTreePayload {
  NeedFilterExclusiveCustomer?: boolean;
  CustomerId?: string;
  NamingStyle?: number;
  CodeFilterOut?: string[];
}

export interface GetShuffleRoomBindingCountPayload {
  GroupLocationId: string;
}

export interface ShuffleRoomBindingCount {
  ShuffleRoomId?: string;
  Name: string;
  BindingCount: number;
  BindingCountLimit: number;
}

export default {
  search(searchModel: ISearchModel): AxiosPromise<SearchResponseBaseModel<LocationSet[]>> {
    return api.post('/Location/Search', searchModel);
  },

  get(): AxiosPromise<ResponseBaseModel<LocationSet[]>> {
    return api.get('/Location');
  },

  post(locationSet: LocationSet): AxiosPromise<ResponseBaseModel<LocationSet>> {
    return api.post('/Location', locationSet);
  },

  put(locationSet: LocationSet): AxiosPromise<ResponseBaseModel<string>> {
    return api.put('/Location', locationSet);
  },

  delete(Id: string): AxiosPromise<ResponseBaseModel<string>> {
    return api.delete(`/Location/${Id}`);
  },

  getLocationCode(getLocationCodePayload: GetLocationCodePayload): AxiosPromise<ResponseBaseModel<string>> {
    return api.post('/Location/GetLocationCode', getLocationCodePayload);
  },

  getLocationTree(getLocationTreePayload?: GetLocationTreePayload): AxiosPromise<ResponseBaseModel<LocationSet[]>> {
    return api.post('/Location/GetLocationTree', getLocationTreePayload);
  },

  getMemberLocationTree(
    getMemberLocationTreePayload?: GetMemberLocationTreePayload
  ): AxiosPromise<ResponseBaseModel<LocationSet[]>> {
    return api.post('/Location/GetMemberLocationTree', getMemberLocationTreePayload);
  },

  getShuffleRoomBindingCount(
    getShuffleRoomBindingCountPayload: GetShuffleRoomBindingCountPayload
  ): AxiosPromise<ResponseBaseModel<ShuffleRoomBindingCount[]>> {
    return api.post('/Location/GetShuffleRoomBindingCount', getShuffleRoomBindingCountPayload);
  },

  getAuthLocationIdList(): AxiosPromise<ResponseBaseModel<string[]>> {
    return api.get('/Location/AuthList');
  }
};
